import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

class MenuBase extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <div>
          <Cross>
            <Icon size="large" url="sprites.svg#chevron" onClick={this.props.onClose}/>
          </Cross>
          <Logo>
            <use xlinkHref={"sprites.svg#water-logo"}></use>
          </Logo>
          <div>
            {p.children}
          </div>
          <Copyright>
            &copy; Longline Environment Ltd. 2020. All rights reserved.
          </Copyright>
        </div>
        <div onClick={this.props.onClose}></div>
      </div>
    );
  }
}

const BG_OPACITY = 0.5;
const MENU_WIDTH = 450;

const Cross = styled('div')`
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
`

const Logo = styled('svg')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #f0f0f0;
  padding: 25px 0 25px 0;
  border-bottom: solid 1px #ddd;
`

const Copyright = styled('div')`
  position: absolute;
  border-top: solid 1px #ddd;
  left: 0;
  bottom: 0;
  width: ${MENU_WIDTH}px;
  text-align: center;
  color: #888;
  font-size: 12px;
  padding: 15px 0 15px 0;
  background: #f0f0f0;
`

const Menu = styled(MenuBase)`
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;

  &>div:first-child {
    position: absolute;
    z-index: 20;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${MENU_WIDTH}px;
    background: #fff;
    transition: left 0.3s ease;
    /* Dropshadow on the right */ 
    box-shadow: 1px 0px 5px 0px rgb(0,0,0,0.5);    
    box-sizing: border-box;
    padding: 210px 0 20px 0;
  }

  &>div:last-child {
    position: absolute;
    z-index: 15;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #333;
    opacity: ${BG_OPACITY};
  }  

  &.item-enter {
    &>div:first-child {
      left: -${MENU_WIDTH}px;
    }
    &>div:last-child {
      opacity: 0;
    }
  }

  &.item-enter-active {
    &>div:first-child {
      left: 0;
      transition: left 300ms;
    }
    &>div:last-child {
      opacity: ${BG_OPACITY};
      transition: opacity 300ms;
    }    
  }

  &.item-exit {
    &>div:first-child {
      left: 0;
    }
    &>div:last-child {
      opacity: ${BG_OPACITY};
    }    
  }

  &.item-exit-active {
    &>div:first-child {
      left: -${MENU_WIDTH}px;
      transition: left 300ms;
    }
    &>div:last-child {
      opacity: 0;
      transition: opacity 300ms;
    }
  }
`

export { Menu };
