import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Flex } from '@independent-software/typeui/controls/Flex';

import { IconOption } from './IconOption';

interface IProps {
  className?: string;
}

interface IState {
  open: boolean;
}

class TicTacToeBase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleToggle = () => {
    this.setState((prevState) => { return {
      open: !prevState.open
    }});
  }  

  handleClose = () => {
    this.setState({
      open: false
    });
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Icon size="large" name="th" onClick={this.handleToggle}/>
        <Panel open={this.state.open} onClose={this.handleClose} padded>
          <Flex>
            <Flex.Row>
              <Flex.Column width={1}>
                <IconOption name="aqua-prime-logo" title="AquaPrime" href="https://www.aquaprime.tech/"/>
              </Flex.Column>
              <Flex.Column width={1}>
                <IconOption name="aqua-investor-logo" title="AquaInvestor" href="https://www.aquainvestor.eu/"/>
              </Flex.Column>
            </Flex.Row>
            <Flex.Row>
              <Flex.Column width={1}>
                <IconOption name="aqua-risk-logo" title="AquaRisk" href="https://www.aquarisk.tech/"/>
              </Flex.Column>
              <Flex.Column width={1}>
                <IconOption name="aqua-scape-logo" title="AquaScape" href="http://www.aquascape.tech/"/>
              </Flex.Column>
            </Flex.Row>            
          </Flex>        
        </Panel>
      </div>
    );
  }
}

const TicTacToe = styled(TicTacToeBase)`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
  width: 40px;
  text-align: center;
`

export { TicTacToe };
