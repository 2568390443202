type TOperator = 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'like';
type TDir = 'asc' | 'desc';

interface IFilter {
  name: string;
  value: any;
  operator: string;
}

/*
 * A query is a collection of filters and an order.
 * - A query can be stored as a string and restored from a string.
 * - A quey can be be packed as a URL query string.
 */
class Query {
  public filters: IFilter[];
  public order: string;
  public dir: TDir;

  constructor(order: string, dir?: TDir) {
    this.filters = [];
    this.order = order;
    this.dir = dir ? dir : 'asc';
  }

  /**
   * Add a filter. Chainable.
   */
  public add = (name: string, operator: TOperator, value: any): Query => {
    this.filters.push({ name: name, operator: operator, value: value});
    return this;
  }

  /**
   * Return all filters with the given name.
   */
  public getByName = (name: string): IFilter[] => {
    return this.filters.filter((x) => x.name === name);
  }

  /*
   * Return all values of filters with the given name.
   */
  public getValuesByName = (name: string): any[] => {
    return this.filters.filter((x) => x.name === name).map((x) => x.value );
  }
  
  /*
   * Get the value of the first filter with the given name.
   * If there is no filter with this name, return null.
   */
  public getValue = (name: string): any => {
    let values = this.getValuesByName(name);
    if(values.length == 0) return null;
    return values[0];
  }

  /**
   * Remove all filters with the given name. Chainable.
   */
  public removeByName = (name: string): Query => {
    this.filters = this.filters.filter((x) => x.name !== name);
    return this;
  }

  /*
   * Pack this query into a JSON string.
   */
  /* public pack = (): string => {
    return JSON.stringify(this);
  } */

  /*
   * Unpack a query from a JSON string.
   */
  /* public static unpack = (json: string): Query => {
    let obj:Query = JSON.parse(json);
    let q = new Query();
    q.filters = obj.filters.map((x) => new Filter(x.name, x.operator as TOperator, x.value));
    q.order = obj.order;
    q.dir = obj.dir;
    q.scrollTop = obj.scrollTop;
    return q;
  } */

  public toUrl = (): string => {
    let order = this.order ? `order=${this.order}&dir=${this.dir}` : '';
    let filters = this.filters.map((filter:IFilter) => `${filter.name}[]=${filter.operator}-${filter.value}`).join('&');
    return order + (order && filters && '&') + filters;
  }
  
}

export { TOperator, TDir, Query };
