import * as React from 'react';
import { ToastContainer } from '@independent-software/typeui/controls/Toast';
import { Water } from './components/Water/Water';
import { Auth } from './services';

interface IAppContainerState {
  // Auth data (or null)
  auth: Auth;
}

class AppContainer extends React.Component<{}, IAppContainerState> {
  constructor(props:{}) {
    super(props);
    let auth = Auth.restore();
    this.state = {
      auth: auth
    };    
  }

  render() {
    let p = this.props;

    return (
      <React.Fragment>
        <ToastContainer maxToasts={5}/>
        <Water auth={this.state.auth}>
          Hello.
        </Water>
      </React.Fragment>
    );
  }
}

export { AppContainer };