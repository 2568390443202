import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { StyleReset } from '@independent-software/typeui/styles/StyleReset';
import { StyleBase } from '@independent-software/typeui/styles/StyleBase';
import { Theme }  from '@independent-software/typeui/styles/Theme';
import { AppContainer } from './AppContainer';

ReactDOM.render(
  (<React.Fragment>
     <StyleReset/>
     <StyleBase/>
     <ThemeProvider theme={Theme}>
       <AppContainer/>
     </ThemeProvider>
   </React.Fragment>),
  document.getElementById('root')
); 

// Whenever webpack rebuilds the project, refresh the browser.
declare let module: any;
if (module.hot) {
  module.hot.accept(); 
}