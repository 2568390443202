import { apiURL } from '../config/Config';
import axios from 'axios';

interface IAuthProps {
  auth: Auth;
}

class Auth {
  public id: number;
  public name: string;
  public email: string;
  public token: string;
  
  constructor(id: number, name: string, email: string, token: string) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.token = token;

    // Store auth in session storage.
    sessionStorage.setItem('auth', JSON.stringify(this));
  }

  /**
   * When the app first loads, this method is used to restore an Auth
   * from local storage. If it's not in local storage, then NULL 
   * is returned. 
   * 
   * This way, we can persist authorization across sessions (including
   * page refreshes).
   */
  public static restore(): Auth {
    let authStr = sessionStorage.getItem('auth');
    if(!authStr) return new Auth(
      0, '', '', ''
    );
    let auth = JSON.parse(authStr);
    return new Auth(
      auth.id,
      auth.name,
      auth.email,
      auth.token
    );
  }

  /**
   * Perform logout by removing session from session storage.
   */
  public logout() {
    axios.get(`${apiURL}/logout?api_token=${this.token}`)
    .then(response => {
      sessionStorage.removeItem('auth');
    })
    .catch(error => {
    });    
  }
}

export { Auth, IAuthProps };