import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';

type TSuitabilityType = 'base' | 'cc' | 'diff';

interface IProps {
  className?: string;
  suitabilityType: TSuitabilityType;
  onChange: (value: TSuitabilityType) => void;
  disabled?: boolean;
}

class SuitabilityToggleBase extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  handleClick = (value: TSuitabilityType) => {
    if(this.props.disabled) return;
    this.props.onChange(value);
  }

  render = () => {
    return (
      <div className={this.props.className}>
        <div className={this.props.suitabilityType == 'base' ? 'active' : ''} onClick={() => this.handleClick('base')}>Base</div>
        <div className={this.props.suitabilityType == 'cc' ? 'active' : ''} onClick={() => this.handleClick('cc')}>Climate change</div>
        <div className={this.props.suitabilityType == 'diff' ? 'active' : ''} onClick={() => this.handleClick('diff')}>Difference</div>
      </div>
    )
  }
}

const SuitabilityToggle = styled(SuitabilityToggleBase)`
  display: flex;
  width: 100%;
  flex-direction: row;
  border: solid 1px #DEDEDE;
  border-radius: 5px;
  margin-bottom: 15px;
  user-select: none;
  &>div {
    cursor: ${p => p.disabled ? 'auto' : 'pointer'};
    text-align: center;
    flex-grow: 1;
    transition: background-color 0.15s ease;
    color: ${p => p.disabled ? '#888' : '#333'};
  }
  ${p => !p.disabled && css`
    &>div:hover {
      background: #eee;
    }
  `}
  &>div.active {
    color: ${p => p.disabled ? '#eee' : '#fff'};
    background: ${p => p.disabled ? '#888' : '#2185D0'};
    border-top: solid 1px #888;
    border-bottom: solid 1px #888;
  }
  &>div:not(:last-child) {
    border-right: solid 1px #DEDEDE;
  }
  &>div:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &>div:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }  
`

export { SuitabilityToggle, TSuitabilityType };