import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import axios from 'axios';
import { TLayer } from './Layer';
import { apiURL } from '../../config/Config';
import { DarkLabels, LightLabels } from '../../components/Water/MapStyle';
import { Species } from '../../resource';
import { TSuitabilityType } from './SuitabilityToggle';
import { TRcp } from './RcpToggle';

interface IMapProps {
  className?: string;
  children?: React.ReactNode;
  options: google.maps.MapOptions;
  species: Species;
  rcp: TRcp;
  suitabilityType: TSuitabilityType;
  layer: TLayer;
  satellite: boolean;
  drawContours: boolean;
  levels: number;
  tileinfo: boolean;
}

class MapBase extends React.Component<IMapProps, {}> {
  // Reference to <div> container element.
  private containerElement: HTMLDivElement;
  private map: google.maps.Map;
  private darkLabelsMapType: google.maps.StyledMapType;
  private lightLabelsMapType: google.maps.StyledMapType;

  constructor(props: IMapProps) {
    super(props);
  }

  createLayerMapType = () => {
    let layer = this.props.layer as string;
    if(layer.substr(0, 11) == 'suitability') layer = layer + "-" + this.props.suitabilityType;
    let contours = this.props.drawContours ? '1' : '0';
    let levels = this.props.levels;
    let species = this.props.species;
    let tileinfo = this.props.tileinfo ? '1': '0';
    let rcp = this.props.rcp;
    let layerMapType = new google.maps.ImageMapType({
      getTileUrl: function(coord, zoom) {
        return `${apiURL}/tile/${species == null ? 0 : (species as any).PKSpecies}/${layer}/${rcp}/${zoom}/${coord.x}/${coord.y}/${contours}/${levels}/${tileinfo}`;
      },
      tileSize: new google.maps.Size(256, 256),
      maxZoom: 6,
      minZoom: 0,
      name: 'layer'
    });
    return layerMapType;
  }

  // Create Google Map after mounting.
  componentDidMount() {
    this.map = new google.maps.Map(this.containerElement, this.props.options);
    this.map.data.setStyle(this.handleStyle);

    this.lightLabelsMapType = new google.maps.StyledMapType(LightLabels);
    this.darkLabelsMapType = new google.maps.StyledMapType(DarkLabels);

    let layerMapType = this.createLayerMapType();

    if(this.props.layer != 'none') this.map.overlayMapTypes.insertAt(0, layerMapType);
    this.map.overlayMapTypes.insertAt(1, this.props.satellite ? this.lightLabelsMapType : this.darkLabelsMapType);
  }

  componentDidUpdate(prevProps: IMapProps) {
    if(this.props.layer != prevProps.layer || this.props.rcp != prevProps.rcp || this.props.suitabilityType != prevProps.suitabilityType || this.props.species != prevProps.species || this.props.tileinfo != prevProps.tileinfo || this.props.drawContours != prevProps.drawContours || this.props.levels != prevProps.levels) {
      this.map.overlayMapTypes.removeAt(0);
      if(this.props.layer != 'none') {
        let layerMapType = this.createLayerMapType();
        this.map.overlayMapTypes.insertAt(0, layerMapType);
      }
    }
    if(this.props.satellite != prevProps.satellite) {
      this.map.setMapTypeId(this.props.satellite ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP);
      this.map.overlayMapTypes.removeAt(1);
      this.map.overlayMapTypes.insertAt(1, this.props.satellite ? this.lightLabelsMapType : this.darkLabelsMapType);
    }
  }

  getLevelColor = (level: number): string => {
    switch(level) {
      case 1: return '#D7191C';
      case 2: return '#FDAE61';
      case 3: return '#EDEF29';
      case 4: return '#A6D96A';
      case 5: return '#1A9641';
    }
  }

  handleStyle = (feature: google.maps.Data.Feature): google.maps.Data.StyleOptions => {
    let color = this.getLevelColor(feature.getProperty('level'));
    return {
      strokeWeight: 0,
      strokeOpacity: 0,
      strokeColor: '#333',
      fillColor: color,
      fillOpacity: 0.75
    };
  }
 
  render() {
    let p = this.props;
    return (
      <div className={p.className} ref={(el:any) => this.containerElement = el}>
        {p.children}
      </div>
    );
  }  
}

const Map = styled(MapBase)`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

export { Map };