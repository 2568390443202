import { apiURL } from '../config/Config';
import { ResourceFactory, Model } from '../services/';

class Species extends Model {
  public CoreName: string = '';

}

let SpeciesFactory = ResourceFactory.create<Species>(Species, `${apiURL}/species`);

export { Species, SpeciesFactory };