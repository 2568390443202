import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  className?: string;
  onClick: () => void;
}

class HelpButtonBase extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Icon size="large" url="sprites.svg#info" onClick={p.onClick}/>
      </div>
    );
  }
}

const HelpButton = styled(HelpButtonBase)`
  position: absolute;
  right: 60px;
  top: 20px;
  z-index: 1;
  width: 40px;
  text-align: center;
`

export { HelpButton };
