import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import { SpeciesFactory, Species } from '../../resource';
import { Query, IAuthProps } from '../../services';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Label } from '@independent-software/typeui/controls/Label';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  className?: string;
  value: Species;
  onToggleMenu: () => void;
  onChange: (species: Species) => void;
}

interface IState {
  species: Species[];
}

class SearchBase extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);
    this.state = {
      species: [],
    }
  }

  componentDidMount() {
    // Retrieve a list of species:
    SpeciesFactory.getSome(this.props.auth, 0, 8, new Query('CoreName', 'asc'))
      .then((res) => this.setState({ species: res.items }));        
  }

  handleChange = (value: Species) => {
    this.props.onChange(value);
  }

  handleSpeciesSearch = (q:string) => {
    // Retrieve a list of species:
    let query = new Query('CoreName', 'asc');
    query.add('q', 'like', q);
    SpeciesFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ species: res.items }));
  }    

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Dropdown onSearch={this.handleSpeciesSearch} value={this.props.value} name="species" fluid data={this.state.species} placeholder="Species" label={(item:Species) => item.CoreName} onChange={this.handleChange}>
          <Label onClick={this.props.onToggleMenu} basic color="#E0E1E2"><Icon color="#333" name="bars"/></Label>
          <Dropdown.Column weight={1}>{(item:Species) => item.CoreName}</Dropdown.Column>
        </Dropdown>
      </div>
    );
  }
}

const Search = styled(SearchBase)`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  width: 300px;
`

export { Search };
