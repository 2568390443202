import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import { darken } from '@independent-software/typeui/helper/darken';
import { Number } from '@independent-software/typeui/formatters/Number';

interface IProps {
  className?: string;
  color: string;
  name: string;
  area: number;
  percentage: number;
}

class LegendItemBase extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <div>
          {p.name}
        </div>
        <div>
          <Number value={p.area} decimals={0}/> km<sup>2</sup> (<Number value={p.percentage} decimals={0}/>%)
        </div>
      </div>
    );
  }
}

const LegendItem = styled(LegendItemBase)`
  display: block;
  position: relative;
  padding-left: 18px;
  margin-right: 16px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    background-color: ${p => p.color};
    border-radius: 4px;
    border: solid 1px ${p => darken(0.2, p.color)};
    box-shadow: 0px 0px 3px rgb(0,0,0,.3);
  }
  &>div {
    display: block;
    line-height: 1.2em;
  }
  &>div:not(:first-child) {
    font-size: 10px;
  }
`

export { LegendItem };
