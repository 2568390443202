const BlankCountries: google.maps.MapTypeStyle[] = [
  {
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  }
];

const LightLabels : google.maps.MapTypeStyle[] = [
  {
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"administrative",
    "elementType":"geometry",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"administrative.country",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"administrative.country",
    "elementType":"labels",
    "stylers":[
      {
        "visibility":"on"
      }
    ]
  },
  {
    "featureType":"administrative.country",
    "elementType":"labels.text.fill",
    "stylers":[
      {
        "color":"#ffffff"
      }
    ]
  },  
  {
    "featureType":"administrative.country",
    "elementType":"labels.text.stroke",
    "stylers":[
      {
        "color":"#0b0406"
      }
    ]
  },    
  {
    "featureType":"poi",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"road",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"road",
    "elementType":"labels.icon",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"transit",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  }
];

const DarkLabels : google.maps.MapTypeStyle[] = [
  {
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"administrative",
    "elementType":"geometry",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"administrative.country",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"administrative.country",
    "elementType":"labels",
    "stylers":[
      {
        "visibility":"on"
      }
    ]
  },
  {
    "featureType":"administrative.country",
    "elementType":"labels.text.fill",
    "stylers":[
      {
        "color":"#000000"
      }
    ]
  },  
  {
    "featureType":"administrative.country",
    "elementType":"labels.text.stroke",
    "stylers":[
      {
        "color":"#ffffff"
      }
    ]
  },    
  {
    "featureType":"poi",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"road",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"road",
    "elementType":"labels.icon",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"transit",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  }
];


export { BlankCountries, DarkLabels, LightLabels };