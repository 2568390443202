import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';

type TRcp = '452050' | '452100' | '852050' | '852100';

interface IProps {
  className?: string;
  rcp: TRcp;
  onChange: (value: TRcp) => void;
  disabled?: boolean;
}

class RcpToggleBase extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  handleClick = (value: TRcp) => {
    if(this.props.disabled) return;
    this.props.onChange(value);
  }

  render = () => {
    return (
      <div className={this.props.className}>
        <div className={this.props.rcp == '452050' ? 'active' : ''} onClick={() => this.handleClick('452050')}>4.5/2050</div>
        <div className={this.props.rcp == '452100' ? 'active' : ''} onClick={() => this.handleClick('452100')}>4.5/2100</div>
        <div className={this.props.rcp == '852050' ? 'active' : ''} onClick={() => this.handleClick('852050')}>8.5/2050</div>
        <div className={this.props.rcp == '852100' ? 'active' : ''} onClick={() => this.handleClick('852100')}>8.5/2100</div>
      </div>
    )
  }
}

const RcpToggle = styled(RcpToggleBase)`
  display: flex;
  width: 100%;
  flex-direction: row;
  border: solid 1px #DEDEDE;
  border-radius: 5px;
  margin-bottom: 15px;
  user-select: none;
  &>div {
    cursor: ${p => p.disabled ? 'auto' : 'pointer'};
    text-align: center;
    flex-grow: 1;
    transition: background-color 0.15s ease;
    color: ${p => p.disabled ? '#888' : '#333'};
  }
  ${p => !p.disabled && css`
    &>div:hover {
      background: #eee;
    }
  `}
  &>div.active {
    color: ${p => p.disabled ? '#eee' : '#fff'};
    background: ${p => p.disabled ? '#888' : '#2185D0'};
    border-top: solid 1px #888;
    border-bottom: solid 1px #888;
  }
  &>div:not(:last-child) {
    border-right: solid 1px #DEDEDE;
  }
  &>div:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &>div:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }  
`

export { RcpToggle, TRcp };