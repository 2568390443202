import axios, { AxiosResponse } from 'axios';
import { Query } from './Query';
import { Auth } from './Auth';

/**
 * All Models derive from the Model class. It gives every model
 * a numeric ID fields, and adds $save, $update and $delete methods.
 * 
 * Models are classes, not interfaces, so that additional methods may
 * be defined on them (like calculated fields).
 */
class Model {
  id?: number;
  $save?: (auth:Auth) => Promise<AxiosResponse>;
  $update?: (auth:Auth) => Promise<AxiosResponse>;
  $delete?: (auth:Auth) => Promise<AxiosResponse>;
}

/**
 * A Resource is responsible for creating an instance of a model. It
 * offers methods for getting one or multiple model instances from a r
 * remote server.
 * 
 * Instantiated models are provided with $save, $update and $delete methods.
 * 
 * Example
 * 
 * // (the factory is created only once.)
 * let UserFactory = ResourceFactory.create<User>(User, 'http://api.rdb/user');
 * // Fetch a user by ID
 * UserFactory.get(1).then(user:User => console.log(user));
 * ...
 * // Save changes
 * user.$save();
 * 
 */
class Resource<T> {
  // Type is the instantiation method for type T.
  private type: new() => T;
  // URL is the remote REST endpoint (with no trailing slash).
  private url: string;

  // Create an instance of this Resource. 
  // Stores model instantiation method and REST url for later use.
  constructor(type: new() => T, url: string) {
    this.type = type;
    this.url = url;
  }

  // Create an instance of model T, with data.
  // $save, $update and $delete methods are connected to the instance after
  // creation.
  public create(data?:object): T {
    let item = new this.type();
    Object.assign(item, data ? data : {});
    // Add save, update and delete methods.
    (item as any).$save = this.save.bind(item, this.url);
    (item as any).$update = this.update.bind(item, this.url);
    (item as any).$delete = this.delete.bind(item, this.url);
    return item;
  }

  // Saves model to REST endpoint.
  // Returns a promise.
  private save(url:string, auth:Auth) {
    return new Promise((resolve, reject) => {
      axios.post(url, { ...this, api_token: auth.token })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });    
  }  

  // Updates model at REST endpoint.
  // Returns a promise.
  private update(url:string, auth: Auth) {
    return new Promise((resolve, reject) => {
      axios.put(url + "/" + (this as any).id, { ...this, api_token: auth.token })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });    
  }

  // Deletes model from REST endpoint.
  // Returns a promise.
  private delete(url:string, auth: Auth): Promise<null> {
    return new Promise((resolve, reject) => {
      axios.delete(url + "/" + (this as any).id + "?api_token=" + auth.token)
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });      
  }

  // Get a list of items.
  public getSome(auth: Auth, offset: number, count: number, query: Query): Promise<{ items: T[], count: number}> {
    return new Promise((resolve, reject) => {
      // Create URL query string:
      let queryUrl = query.toUrl();
      if(queryUrl) queryUrl = '&' + queryUrl;
      let params = `api_token=${auth.token}&offset=${offset}&count=${count}${queryUrl}`;
      // Perform request, with params as URL string:
      axios.get(this.url + 's?' + params)
        .then(response => {
          let items: T[] = response.data.data.map((obj:any) => this.create(obj));
          resolve({items: items, count: response.data.count});
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // Get a single item.
  public get(auth: Auth, id: number): Promise<T> {
    return new Promise((resolve, reject) => {
      axios.get(`${this.url}/${id}?api_token=${auth.token}`)
        .then(response => {
          // Item retrieved successfully. Create a new instance
          let item:T = this.create(response.data);
          // Resolve promise by returning instance.
          resolve(item);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

/**
 * The ResourceFactory creates instances of Resource, by
 * providing it with a model type and a remote REST url.
 * 
 * e.g. ResourceFactory.create<User>(User, 'http://api.rdb/user');
 */
class ResourceFactory {
  public static create<T>(type: { new(): T;}, url: string) {
    // We use type to extract the new() method from type T,
    // so that we can create instances of T later.
    return new Resource<T>(type, url);
  }
}

export { Model, Resource, ResourceFactory };