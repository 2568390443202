import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Header } from '@independent-software/typeui/controls/Header';
import { Image } from '@independent-software/typeui/controls/Image';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { HelpLogo } from './HelpLogo';

interface IProps {
  className?: string;
  onClose: () => void;
}

class HelpBase extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <div>
          <Cross>
            <Icon size="large" name="times" onClick={this.props.onClose}/>
          </Cross>
          <Content>
            <Logo>
              <use xlinkHref={"sprites.svg#water-logo"}></use>
            </Logo>            

            <Big>
              <p>
                <b>WATER</b> means <i>Where can Aquaculture Thrive in EuRope</i> &mdash; the app helps you
                choose where to grow fish.
              </p>
            </Big>

            <Header size="h3">What does WATER do?</Header>

            <p>
              You can use WATER to map depth, wave height, oxygen, and other parameters in regional seas. 
              You can also use it to find out how well a particular species will grow in marine 
              waters &mdash; WATER does this by analysing suitability; it uses Big Data from satellite 
              remote sensing and computer models to evaluate multiple criteria such as depth for cage 
              moorings and appropriate water temperature and dissolved oxygen.
            </p>

            <Header size="h3">Suitability Study</Header>

            <p>
              You might for instance choose Atlantic salmon or European seabass and then click 
              the <i>Suitability</i> option in the left-hand box to view a map scaled from one 
              (bad) to five (highly suitable). The map will show suitability as the result of a 
              Big Data index that combines six different parameters &mdash; but you can also see 
              the results for any one parameter, e.g. water temperature, by selecting it.              
            </p>

            <p>
              Suitable areas for aquaculture are shifting as our environment changes &mdash; WATER 
              recognises this, and builds in the capacity to determine how the Blue Economy will 
              be affected by climate change. Climate models that predict shifts in water temperature, 
              oxygen, and other factors are used in WATER to help farmers, managers, and investors 
              understand how business may be affected by such changes.
            </p>

            <p>
              The <i>Suitability</i> option allows you to predict changes for both moderate and more 
              extreme climate change scenarios, considering a medium-term (mid-century) and long-term 
              (end-of-century) time horizon.
            </p>

            <Header size="h3">Development</Header>

            <p>
              WATER was developed by <b><a href="https://www.longline.co.uk/" target="_blank">Longline 
              Environment Ltd.</a></b>, and partly supported by the European Union Horizon 2020 programme, 
              through the research projects below. The links provide more information on the origins 
              of data and modelling results used by WATER, and the participating institutions.
            </p>

            <Header size="h3">Resources</Header>

            <p>
              Suitability ranges of environmental parameters for 45 cultivated species are freely 
              available <b><a href="https://longline.co.uk/meta" target="_blank">here</a></b>.
            </p>

            <Divider/>
            
            <HelpLogo url="https://ec.europa.eu/programmes/horizon2020/" src="img/horizon2020.png"/>
            <HelpLogo url="https://www.gain2020.com/" src="img/gain.png"/>
            <HelpLogo url="https://ceresproject.eu/" src="img/ceres.png"/>
            <HelpLogo url="http://www.aquaspace-h2020.eu/" src="img/aquaspace.png"/>
          </Content>
        </div>
        <div onClick={this.props.onClose}></div>
      </div>
    );
  }
}

const BG_OPACITY = 0.5;

const Cross = styled('div')`
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
`

const Content = styled('div')`
  overflow-y: scroll;
  margin-top: 30px;
  padding: 0 10px 0 10px;
  height: 95%;
`

const Help = styled(HelpBase)`
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;

  &>div:first-child {
    position: absolute;
    z-index: 20;
    left: 20%;
    top: 0;
    bottom: 0;
    width: 60%;
    background: #fff;
    transition: left 0.3s ease;
    /* Dropshadow on the right */ 
    box-shadow: 1px 0px 5px 0px rgb(0,0,0,0.5);    
    box-sizing: border-box;
    padding: 20px 20px 20px 20px;
  }

  &>div:last-child {
    position: absolute;
    z-index: 15;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #333;
    opacity: ${BG_OPACITY};
  }  

  &.item-enter {
    &>div:first-child {
      left: -100%;
    }
    &>div:last-child {
      opacity: 0;
    }
  }

  &.item-enter-active {
    &>div:first-child {
      left: 0;
      transition: left 300ms;
    }
    &>div:last-child {
      opacity: ${BG_OPACITY};
      transition: opacity 300ms;
    }    
  }

  &.item-exit {
    &>div:first-child {
      left: 0;
    }
    &>div:last-child {
      opacity: ${BG_OPACITY};
    }    
  }

  &.item-exit-active {
    &>div:first-child {
      left: -100%;
      transition: left 300ms;
    }
    &>div:last-child {
      opacity: 0;
      transition: opacity 300ms;
    }
  }
`

const Big = styled('div')`
  font-size: 130%;
  margin-bottom: 25px;
  text-align: center;
  line-height: 1.5em;
`

const Logo = styled('svg')`
  width: 100%;
  margin-bottom: 35px;
`

export { Help };

