import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Flex } from '@independent-software/typeui/controls/Flex';

interface IProps {
  className?: string;
  name: string;
  title: string;
  href: string;
}

class IconOptionBase extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <a className={p.className} href={p.href} target="_blank">
        <svg>
          <use xlinkHref={"sprites.svg#" + p.name}></use>
        </svg>        
        <span>{p.title}</span>
      </a>
    );
  }
}

const IconOption = styled(IconOptionBase)`
  cursor: pointer;
  background-color: #fff;
  text-decoration: none;
  color: #333;
  
  &>svg {
    width: 60px;
    height: 60px;
    transition: opacity 0.1s ease, transform 0.1s ease-in-out;
    transform-origin: center center;
  }
  &>span {
    border-radius: 4px;
    font-weight: 500;
    font-size: 11px;
    white-space: nowrap;
    padding: 2px 6px 2px 6px;
    transition: background-color 0.1s ease;
  }

  &:hover {
    &>svg {
      opacity: 0.8;
      transform: scale(1.3);
    }
    &>span {
      background-color: #eee;
    }
  }
`

export { IconOption };
