import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Number } from '@independent-software/typeui/formatters/Number';
import axios from 'axios';
import { css } from 'styled-components';
import { LegendItem } from './LegendItem';
import { TLayer } from './Layer';
import { apiURL } from '../../config/Config';
import { TSuitabilityType } from './SuitabilityToggle';
import { Species } from '../../resource';
import { TRcp } from './RcpToggle';

interface IProps {
  className?: string;
  species: Species;
  rcp: TRcp;
  suitabilityType: TSuitabilityType;
  layer: TLayer;
}

interface IState {
  type: string;
  min: number;
  max: number;
  unit: string;
  name: string;
  stops: any[];
  categories: any[];
  areas: number[];
  percentages: number[];
}

class LegendBase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      type: '',
      name: '',
      min: 0,
      max: 0,
      unit: '',
      stops: [],
      categories: [],
      areas: [],
      percentages: []
    }
  }

  getData = () => {
    let p = this.props;
    let layer = p.layer as string;
    if(layer.substr(0, 11) == 'suitability') layer = layer + "-" + p.suitabilityType;
    axios.get(`${apiURL}/legend/${p.species == null ? 0 : (p.species as any).PKSpecies}/${layer}/${p.rcp}`)
    .then(response => {
      this.setState(response.data);
    });
  }

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate(prevProps: IProps) {
    if(this.props.layer != prevProps.layer || this.props.suitabilityType != prevProps.suitabilityType || this.props.species != prevProps.species || this.props.rcp != prevProps.rcp) {
      this.getData();
    }
  }  

  render() {
    let p = this.props;

    // If no data is available (yet), do not display legend.
    if(this.state.name == '') return null;

    if(this.state.type == 'scale') {
      // Convert gradient stops to CSS string
      let gradientCSS = this.state.stops.map(stop => `rgb(${stop[1]},${stop[2]},${stop[3]}) ${stop[0]*100}%`).join();
      return (
        <div className={p.className}>
          <Layer>{this.state.name} ({this.state.unit})</Layer>
          <Min><Number value={this.state.min} decimals={2}/></Min>
          <Bar style={{background: `linear-gradient(to right, ${gradientCSS})`}}/>
          <Max><Number value={this.state.max} decimals={2}/></Max>
        </div>
      );
    }

    if(this.state.type == 'category') {
      return (
        <div className={p.className}>
          <Layer>{this.state.name}</Layer>
          {this.state.categories.map((cat, idx) => 
            <LegendItem key={idx} 
              color={`rgb(${cat.color[1]}, ${cat.color[2]}, ${cat.color[3]})`}
              name={cat.name} 
              area={this.state.areas[idx]}
              percentage={this.state.percentages[idx]}/>
          )}
        </div>
      );
    }
  }
}

const Legend = styled(LegendBase)`
  position: absolute;
  display: flex;
  left: 20px;
  bottom: 20px;
  z-index: 1;
  background: #fff;
  border: solid 1px ${p => p.theme.normalColor};
  border-radius: 4px;
  padding: 8px 8px 6px 8px;
  font-size: 12px;
  line-height: 12px;
  box-shadow: 0px 0px 5px rgb(0,0,0,0.3);
`

const Bar = styled('div')`
  background: green;
  height: 11px;
  width: 200px;
`

const Layer = styled('div')`
  font-weight: 500;
  padding-right: 25px;
`

const Min = styled('div')`
  white-space: nowrap;
  padding-right: 8px;
`

const Max = styled('div')`
  white-space: nowrap;
  padding-left: 8px;
`

export { Legend };
