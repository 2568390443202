import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  className?: string;
  url: string;
  src: string;
}

class HelpLogoBase extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    let p = this.props;
    return (
      <a className={p.className} href={p.url} target="_blank">
        <img src={p.src}/>
      </a>
    );
  }
}

const HelpLogo = styled(HelpLogoBase)`
  margin-right: 50px;
`

export { HelpLogo };
