import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components';
import { Species } from '../../resource';
import { IAuthProps } from '../../services';
import { Search } from './Search';
import { Map } from './Map';
import { BlankCountries } from './MapStyle';
import { Menu } from './Menu';
import { CSSTransition } from 'react-transition-group';
import { TLayer } from './Layer';
import { TicTacToe } from './TicTacToe';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Legend } from './Legend';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Message } from '@independent-software/typeui/controls/Message';
import { SuitabilityToggle, TSuitabilityType } from './SuitabilityToggle';
import { RcpToggle, TRcp } from './RcpToggle';
import { HelpButton } from './HelpButton';
import { Help } from './Help';

interface IProps {
  className?: string;
}

interface IState {
  species: Species;
  menuOpen: boolean;
  helpOpen: boolean;
  suitabilityType: TSuitabilityType;
  rcp: TRcp;
  showBathymetry: boolean;
  showSatellite: boolean;
  showTileInfo: boolean;
  drawContours: boolean;
  levels: number;
  layer: TLayer;
  tabIndex: number;
}

class WaterBase extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    this.state = {
      species: null,
      menuOpen: false,
      helpOpen: false,
      suitabilityType: 'base',
      rcp: '452050',
      showBathymetry: true,
      showSatellite: false,
      showTileInfo: false,
      drawContours: false,
      levels: 64,
      layer: 'base-depth',
      tabIndex: 0
    }
  }

  handleChangeSpecies = (value: Species) => {
    this.setState({
      species: value
    });
  }

  handleToggleMenu = () => {
    this.setState(prevState => {
      return {
        menuOpen: !prevState.menuOpen
      }
    });
  }  

  handleToggleHelp = () => {
    this.setState(prevState => {
      return {
        helpOpen: !prevState.helpOpen
      }
    });
  }

  handleToggleBathymetry = (value: boolean) => {
    this.setState(prevState => {
      return {
        showBathymetry: !prevState.showBathymetry
      }
    });  
  }

  handleToggleSatellite = (value: boolean) => {
    this.setState(prevState => {
      return {
        showSatellite: !prevState.showSatellite
      }
    });  
  }

  handleToggleDrawContours = (value: boolean) => {
    this.setState(prevState => {
      return {
        drawContours: !prevState.drawContours
      }
    });  
  }    

  handleToggleTileInfo = (value: boolean) => {
    this.setState(prevState => {
      return {
        showTileInfo: !prevState.showTileInfo
      }
    });  
  }

  handleChangeLevels = (value: number) => {
    this.setState({
      levels: value
    });
  }

  handleChangeLayer = (value: TLayer) => {
    this.setState({
      layer: value
    });
  }

  handleChangeSuitabilityType = (value: TSuitabilityType) => {
    this.setState({
      suitabilityType: value
    });
  }

  handleChangeRcp = (value: TRcp) => {
    this.setState({
      rcp: value
    });
  }  

  handleTabChange = (idx: number) => {
    this.setState({
      tabIndex: idx
    });
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Search auth={this.props.auth} value={this.state.species} onChange={this.handleChangeSpecies} onToggleMenu={this.handleToggleMenu}/>
        <CSSTransition in={this.state.helpOpen} unmountOnExit timeout={300} classNames="item">
          <Help onClose={this.handleToggleHelp}/>
        </CSSTransition>
        <CSSTransition in={this.state.menuOpen} unmountOnExit timeout={300} classNames="item">
          <Menu onClose={this.handleToggleMenu}>
            
            <Tabs underlined active={this.state.tabIndex} onTabChange={this.handleTabChange}>
              <Tabs.Pane label="Base">
                <TabPadding>
                  <div><Checkbox radio name="layer" value="base-depth" type="circle" label="Bathymetry" checked={this.state.layer == 'base-depth'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox radio name="layer" value='base-spd'   type="circle" label="Current speed" checked={this.state.layer == 'base-spd'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox radio name="layer" value='base-swh'   type="circle" label="Significant wave height" checked={this.state.layer == 'base-swh'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox radio name="layer" value='base-o'     type="circle" label="Oxygen" checked={this.state.layer == 'base-o'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox radio name="layer" value='base-chl'   type="circle" label="Chlorophyll" checked={this.state.layer == 'base-chl'} onChange={this.handleChangeLayer}/></div>
                </TabPadding>
              </Tabs.Pane>
              <Tabs.Pane label="Climate change">
                <TabPadding>
                  <div><Checkbox radio name="layer" value='cc-spd'   type="circle" label="Current speed" checked={this.state.layer == 'cc-spd'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox radio name="layer" value='cc-o'     type="circle" label="Oxygen" checked={this.state.layer == 'cc-o'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox radio name="layer" value='cc-chl'   type="circle" label="Chlorophyll" checked={this.state.layer == 'cc-chl'} onChange={this.handleChangeLayer}/></div>
                </TabPadding>
              </Tabs.Pane>
              <Tabs.Pane label="Suitability">
                <TabPadding>
                  <SuitabilityToggle disabled={this.state.species==null} suitabilityType={this.state.suitabilityType} onChange={this.handleChangeSuitabilityType}/>
                  {this.state.species == null && 
                  <Message type="info">Please select a species to display suitability.</Message>
                  }
                  <div><Checkbox disabled={this.state.species==null} radio name="layer" value='suitability-depth' type="circle" label="Bathymetry" checked={this.state.layer == 'suitability-depth'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox disabled={this.state.species==null} radio name="layer" value='suitability-spd' type="circle" label="Current speed" checked={this.state.layer == 'suitability-spd'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox disabled={this.state.species==null} radio name="layer" value='suitability-swh' type="circle" label="Significant wave height" checked={this.state.layer == 'suitability-swh'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox disabled={this.state.species==null} radio name="layer" value='suitability-o' type="circle" label="Oxygen" checked={this.state.layer == 'suitability-o'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox disabled={this.state.species==null} radio name="layer" value='suitability-chl' type="circle" label="Chlorophyll" checked={this.state.layer == 'suitability-chl'} onChange={this.handleChangeLayer}/></div>
                  <div><Checkbox disabled={this.state.species==null} radio name="layer" value='suitability-temp' type="circle" label="Water temperature" checked={this.state.layer == 'suitability-temp'} onChange={this.handleChangeLayer}/></div>
                </TabPadding>
              </Tabs.Pane>          
              <Tabs.Pane label="Options">
                <TabPadding>
                  <RcpToggle rcp={this.state.rcp} onChange={this.handleChangeRcp}/>
                  <Padding>
                    <Checkbox name="satellite" type="toggle" label="Satellite imagery" checked={this.state.showSatellite} onChange={this.handleToggleSatellite}/>
                  </Padding>
                  <Padding>
                    <Checkbox name="contour" type="toggle" label="Draw contours" checked={this.state.drawContours} onChange={this.handleToggleDrawContours}/>
                  </Padding>
                  <Padding>
                    <Checkbox name="tileinfo" type="toggle" label="Tile render timings" checked={this.state.showTileInfo} onChange={this.handleToggleTileInfo}/>
                  </Padding>
                  <Dropdown value={this.state.levels} fluid data={[8, 16, 32, 64, 128]}label={function(item){return "Levels: " + item}} placeholder="Levels" onChange={this.handleChangeLevels}>
                    <Dropdown.Column>{(item) => item}</Dropdown.Column>
                  </Dropdown>
                </TabPadding>
              </Tabs.Pane>                     
            </Tabs>
            
          </Menu>
        </CSSTransition>
        <HelpButton onClick={this.handleToggleHelp}/>
        <TicTacToe/>
        <Legend 
          rcp={this.state.rcp}
          species={this.state.species}
          suitabilityType={this.state.suitabilityType} 
          layer={this.state.layer}/>
        <Map 
          rcp={this.state.rcp}
          suitabilityType={this.state.suitabilityType}
          layer={this.state.layer}
          satellite={this.state.showSatellite}
          drawContours={this.state.drawContours}
          tileinfo={this.state.showTileInfo}
          levels={this.state.levels}
          species={this.state.species}
          options={{
            center: { lat: 46.711380, lng: 1.302726 },
            zoom: 3,
            minZoom: 0,
            maxZoom: 6,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: BlankCountries
          }}
          />
      </div>
    );
  }
}

const Water = styled(WaterBase)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
`

// Padding around checkboxes
const Padding = styled('div')`
  padding: 4px 0 4px 0;
`

// Tabs fills all available width.
// Add a little padding to the tab contents.
const TabPadding = styled('div')`
  padding: 0 20px 0 20px;
`

export { Water };
